import React, {Component} from "react"
import {Link} from "gatsby"
import elasticlunr from 'elasticlunr'

// Search component
export default class Search extends Component {
    constructor(props) {
        super(props)
        this.props = props
        this.state = {
            visible: false,
            query: ``,
            results: [],
            index: null
        }
    }

    componentDidMount() {
        const index = elasticlunr();

        index.addField('title');
        index.setRef('id');

        fetch('/search-index.json')
            .then(result => result.json())
            .then(data => {
                data.forEach(item => index.addDoc(item))
                this.setState({index})
            })

        document.addEventListener('navigate', (e) => {
            this.setState({results: [], query: ''})
        }, false);
    }

    render() {
        return (
            <>
                <div className='relative'>
                    <div className='bg-opaque-brand-grey-lighter py-2 px-4 rounded-full text-gray-800 flex'>
                        <input className='text-white md:text-brand-body bg-transparent outline-none flex-1' type="text"
                               value={this.state.query}
                               onChange={this.search}
                               onFocus={this.focus}
                               onBlur={this.blur}
                        />
                        <span className='text-gray-500'>
                        <i className="fa fa-search"></i>
                    </span>
                    </div>
                    {!this.state.results.length && this.state.query && (
                        <div
                            className={`absolute top-0 inset-x-0 mt-16 text-white md:text-brand-body md:bg-gray-300 rounded-lg overflow-hidden md:shadow text-white px-4 text-center text-lg mt-8 p-4 ${this.state.visible ? 'block' : 'hidden'}`}>
                            Ingen treff funnet. Prøv et annet søkeord.
                        </div>
                    )}
                    {this.state.results.length ? (
                        <ul className={`absolute top-0 inset-x-0 mt-16 text-white md:text-brand-body md:bg-gray-300 rounded-lg overflow-hidden md:shadow ${this.state.visible ? 'block' : 'hidden'}`}>
                            {this.state.results.map(page => (
                                <li key={page.id}
                                    className='text-sm p-4 bg-brand-blue md:bg-transparent bg-opacity-75 mb-4 md:mb-0 rounded-lg flex items-center'>
                                    <Link to={"/" + page.path} onClick={this.onNavigate}>{page.title}</Link>
                                    <div className="w-8 ml-auto text-lg text-white md:text-brand-red text-right">
                                        <span className="fa fa-chevron-right"/>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : ''}
                </div>
            </>
        )
    }

    blur = (evt) => {
        setTimeout(() => this.setState({visible: false}), 400)

    }

    focus = (evt) => {
        this.setState({visible: true})
    }

    onNavigate = (evt) => {
        if (this.props.close) {
            this.props.close()
        }
    }

    search = (evt) => {
        const query = evt.target.value
        let results = this.state.index
            .search(query, {
                title: {
                    boost: 2
                },
                expand: true
            })
            // Map over each ID and return the full document
            .map(({ref}) => this.state.index.documentStore.getDoc(ref));

        if (this.props.limit) {
            results = results.slice(0, this.props.limit)
        }
        this.setState({
            query,
            // Query the index with search string to get an [] of IDs
            results,
        })
    }
}