import React from 'react'
import DesktopHeader from "./header-desktop";
import MobileHeader from "./header-mobile";


const Header = ({className}) => {
    return (
        <>
            <DesktopHeader className={`hidden lg:block ${className}`}/>
            <MobileHeader className={`block lg:hidden ${className}`}/>
        </>
    )
}

export default Header
