/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/styles/layout.css'
import '@fortawesome/fontawesome-free/js/all'
import './src/styles/icon/css/Glyphter.css'
import "react-alice-carousel/lib/alice-carousel.css";
// You can delete this file if you're not using it

export const onRouteUpdate = ({location, prevLocation}) => {
    const event = new Event('navigate');
    document.dispatchEvent(event);
}