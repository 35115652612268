import React from "react";
import Container from "./container";
import {Link} from "gatsby";
import Logo from "./logo";
import DecalThree from "../assets/decal3.svg";
import MenuItems from "../lib/menu";
import Search from "./search";

const Menu = ({show, close}) => (
    <div
        className={`z-40 fixed mt-24 h-screen w-full inset-x-0 top-0 bg-gradient-to-b via-brand-blue from-brand-blue transition duration-200 ease transform ${show ? 'opacity-100 translate-y-0' : 'pointer-events-none opacity-0 -translate-y-full'}`}>
        <div className='w-20 absolute bottom-0 right-0 pt-1/2 h-screen'>
            <img src={DecalThree} className='mix-blend-mode w-full'/>
        </div>
        <nav className='font-heading pt-24'>
            <ul className='list-reset flex flex-col items-center text-sm'>
                {MenuItems && MenuItems.map((item, index) => (
                    <li key={`menu-${index}`} className='mb-4'>
                        <Link
                            onClick={close}
                            className='text-center text-white text-lg font-medium text-brand-grey-dark hover:font-semibold'
                            activeClassName='font-semibold'
                            partiallyActive={true}
                            to={item.url}>{item.name}</Link>
                    </li>
                ))}
                <li className='md:mx-2'>
                    <Link
                        onClick={close}
                        className='text-center text-white text-lg font-medium text-brand-grey-dark'
                        activeClassName='font-semibold'
                        partiallyActive={true}
                        to='/contact'>Kontakt oss</Link>
                </li>
            </ul>
        </nav>
    </div>
)

const SearchBox = ({show, close}) => (
    <div
        className={`z-40 fixed mt-24 h-screen w-full inset-x-0 top-0 bg-gradient-to-b via-brand-blue from-brand-blue transition duration-200 ease transform ${show ? 'opacity-100 translate-y-0' : 'pointer-events-none opacity-0 -translate-y-full'}`}>
        <div className='p-8'>
            <Search limit={5} close={close}/>
        </div>
    </div>
)

class MobileHeader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showNav: false,
            showSearch: false,
        }
        this.className = props.className
        this.toggleNav = this.toggleNav.bind(this);
        this.toggleSearch = this.toggleSearch.bind(this);
    }

    toggleNav() {
        this.setState({
            showNav: !this.state.showNav
        })
    }

    toggleSearch() {
        this.setState({
            showSearch: !this.state.showSearch
        })
    }

    closeMenu = (e) => {
        this.setState({
            showNav: false
        })
    }

    closeSearch = (e) => {
        this.setState({
            showSearch: false
        })
    }

    render() {
        return (
            <div className={this.className}>
                <div
                    className={`inset-x-0 fixed z-50 bg-opaque-white-harder backdrop-blur font-heading h-24 flex items-end pb-4 $`}>
                    <Container className='z-50 relative'>
                        <div className='flex items-center'>
                            <Link to='/' onClick={this.closeMenu} className='mr-8'>
                                <Logo large
                                      className='transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105'/>
                            </Link>
                            <div className="ml-auto flex items-center">
                                <button className='text-brand-grey-lighter text-xl mr-8' onClick={this.toggleSearch}>
                                    <i className="fa fa-search"/>
                                </button>
                                <button className='text-brand-blue text-2xl' onClick={this.toggleNav}>
                                    <i className="fa fa-bars"/>
                                </button>
                            </div>
                        </div>
                    </Container>
                </div>
                <Menu show={this.state.showNav} close={() => {
                    this.closeMenu();
                    this.closeSearch();
                }}/>
                <SearchBox show={this.state.showSearch} close={() => {
                    this.closeMenu();
                    this.closeSearch();
                }}/>
            </div>
        );
    }
}

export default MobileHeader;