import React from 'react'
import Header from './header'
import Footer from "./footer";
import BottomCircle from '../assets/bottom_circle.svg'

const Layout = ({children, siteTitle, location}) => {
    const scrollToTop = () => {

        window.scrollBy({
            top: -window.scrollY,
            left: 0,
            behavior: 'smooth'
        });
    }
    const path = typeof window !== 'undefined' ? window.location.path : '';

    return (
        <div className='relative overflow-x-hidden'>
            <Header siteTitle={siteTitle} className='z-50'/>
            <div className='body-gradient text-brand-body'>
                <div className='pt-24 lg:pt-32 relative z-30'>
                    {children}
                </div>

                {path === '/contact' && (
                    <div className='flex md:hidden justify-center'>
                        <button onClick={scrollToTop}>
                            <div
                                className='transform rotate-180 z-30 text-white text-5xl w-122 h-122 transform hover:scale-105 red-hover flex items-center justify-center rounded-full bg-brand-red-light transition duration-300 ease'>
                                <span
                                    className="block border-b-2 border-r-2 border-white h-10 w-10 transform rotate-45 -mt-5"/>
                            </div>
                            <div className='pt-4 pb-12'>
                                Til toppen
                            </div>
                        </button>
                    </div>
                )}
            </div>
            <img src={BottomCircle} className='absolute bottom-0 right-0'/>
            <Footer className={'relative z-20'}/>
        </div>
    )
}

export default Layout
