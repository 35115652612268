import React from 'react'

const Logo = (props) => {
    let widthClasses = 'w-12 md:w-20'
    if (props.small) {
        widthClasses = 'w-10 md:w-16'
    }
    if (props.large) {
        widthClasses = 'w-16 md:w-24'
    }
    if (props.xlarge) {
        widthClasses = 'w-150'
    }
    return (
        <svg className={`${widthClasses} h-auto ${props.className}`} version='1.0' width="77.601" height="57.965"
             viewBox="0 0 77.601 57.965">
            <path className={`${props.white ? 'text-white' : 'text-brand-red'} fill-current`}
                  d="M42,50.215a6.8,6.8,0,0,1,4.932,2.011,6.689,6.689,0,0,1,0,9.649,7.062,7.062,0,0,1-9.866,0,6.692,6.692,0,0,1,0-9.649A6.806,6.806,0,0,1,42,50.215Z"
                  transform="translate(-35.007 -50.18)"/>
            <path className={`${props.white ? 'text-white' : 'text-brand-red'} fill-current`}
                  d="M147.286,50.215a6.806,6.806,0,0,1,4.935,2.011,6.69,6.69,0,0,1,0,9.649,7.063,7.063,0,0,1-9.867,0,6.692,6.692,0,0,1,0-9.649A6.8,6.8,0,0,1,147.286,50.215Z"
                  transform="translate(-76.675 -50.18)"/>
            <path className={`${props.white ? 'text-white' : 'text-brand-blue'} fill-current`}
                  d="M102.325,54.675,100.1,58.916c-1.977,3.763-5.76,4.69-9.2,3.816a14.393,14.393,0,0,0-4.1-.584,7.521,7.521,0,0,0-4.1,1.167,3.912,3.912,0,0,0-2.164,3.43q0,3.357,6.5,5.254h0c2.054.6,8.682,3.021,9.941,3.609a14.094,14.094,0,0,1,4.159,2.818c2.638,2.583,3.534,5.567,3.534,9.7q0,10-5.83,14.811-6.284,5.185-16.006,5.184a34.485,34.485,0,0,1-19.816-6.276l2.579-4.735a7.048,7.048,0,0,1,6.1-3.744c1.676-.054,2.716.534,4.482,1.354a14.785,14.785,0,0,0,6.118,1.312,8.277,8.277,0,0,0,4.966-1.425,4.651,4.651,0,0,0,2.132-4.125q0-2.632-3.288-4.311A27.839,27.839,0,0,0,80.491,84.2q-8.144-2.259-11.057-4.748-4.187-3.507-4.185-10.812,0-8.327,5.457-13.4t13.978-5.077A35.783,35.783,0,0,1,102.325,54.675Z"
                  transform="translate(-46.089 -50.156)"/>
            <path className={`${props.white ? 'text-white' : 'text-brand-blue'} fill-current`}
                  d="M6.232,0h0a6.232,6.232,0,0,1,6.232,6.232v23.2a6.231,6.231,0,0,1-6.231,6.231h0A6.23,6.23,0,0,1,0,29.436V6.232A6.232,6.232,0,0,1,6.232,0Z"
                  transform="translate(0.712 18.7)"/>
            <path className={`${props.white ? 'text-white' : 'text-brand-blue'} fill-current`}
                  d="M147.887,81.8a6.008,6.008,0,0,1,6.055,5.922v23.82a6.009,6.009,0,0,1-6.055,5.924h-.352a6.009,6.009,0,0,1-6.056-5.924V87.722a6.008,6.008,0,0,1,6.056-5.922Z"
                  transform="translate(-77.142 -63.1)"/>
        </svg>
    )
}

export default Logo
