import React, {Component} from 'react'

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

class NewsletterForm extends Component {
    constructor(props) {
        super(props);
        this.state = {email: "", submitted: false};
    }

    handleSubmit = e => {
        fetch("https://api.ibricks.no/newsletter/profile/optin", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "compDir": "isi",
                "companyName": "iSi AS",
                "consent": true,
                "firstName": "",
                "lastName": "",
                "internetAddress": this.state.email,
                "mailList": [
                    "Nyhetsbrev"
                ],
                "newslatterDisplayName": "iSi nyhetsbrev"
            })
        })
            .then(() => {
                this.setState({submitted: true})
            })
            .catch(error => {
                console.log(error)
            });

        e.preventDefault();
    }

    handleChange = e => {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        const {email} = this.state;
        return (
            <div className='w-full'>
                {this.state.submitted && (
                    <div className='w-full h-24 flex bg-gray-200 items-center'>
                        <div className='text-red-600 text-lg text-center flex-1'>
                            <strong>Tusen takk!</strong> Sjekk eposten din :-)
                        </div>
                    </div>
                )}

                {!this.state.submitted && (
                    <form
                        className='w-full h-12 flex bg-opaque-white-softer items-center rounded'
                        onSubmit={this.handleSubmit}>
                        <div className='flex-1'>
                            <label className='hidden'>Skriv inn din epostadresse</label>
                            <input
                                className='h-12 bg-transparent px-4 w-full placeholder-white appearance-none outline-none'
                                type='email'
                                required
                                placeholder='Epost' name="email" value={email}
                                onKeyUp={this.handleChange}/>
                        </div>
                        <button type='submit'
                                className='bg-transparent text-brand-grey-light h-12 w-12 text-xl flex items-center justify-center hover:text-white'>
                            <span className='fa fa-arrow-right'/>
                        </button>
                    </form>
                )}
            </div>
        )
    }

}

export default NewsletterForm;