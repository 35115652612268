export default [
    {
        url: '/products',
        name: 'Produkter'
    },
    {
        url: '/projects',
        name: 'Prosjekter'
    },
    {
        url: '/archive',
        name: 'Blogg'
    },
    {
        url: '/team',
        name: 'Om oss'
    },
    {
        url: '/karriere',
        name: 'Jobb i iSi'
    },
]