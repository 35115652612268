import React from "react";
import Container from "./container";
import {Link} from "gatsby";
import Logo from "./logo";
import Search from "./search";
import MenuItems from "../lib/menu";

class DesktopHeader extends React.Component {
    constructor(props) {
        super(props)
        this.className = props.className
    }

    render() {
        return (
            <div
                className={`inset-x-0 py-8 fixed z-50 bg-opaque-white-harder backdrop-blur font-heading h-32 ${this.className}`}>
                <Container>
                    <div className='flex items-end mt-3'>
                        <Link to='/' className='w-130'>
                            <Logo
                                className='transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105'/>
                        </Link>
                        <div className='flex-1 flex items-center'>
                            <div className="flex-1 flex items-center">
                                <div className='hidden md:block md:mr-2 -ml-2'>
                                    <Link
                                        className='px-2 font-medium text-sm text-gray-700 hover:text-brand-red transition duration-200 ease-in-out py-1 rounded'
                                        activeClassName='bg-brand-red text-white hover:text-white'
                                        activeStyle={{color: 'white'}}
                                        partiallyActive={true}
                                        to='/contact'>
                                        Kontakt oss
                                    </Link>
                                </div>
                                <div className='mx-auto max-w-xs w-1/5xl:w-full'>
                                    <Search limit={10}/>
                                </div>


                                <nav className={`md:ml-auto font-heading`}>
                                    <ul className='list-reset flex flex-col md:flex-row -mr-2 md:items-center text-sm'>
                                        {MenuItems && MenuItems.map((item, index) => (
                                            <li key={`menu-${index}`} className='md:ml-4'>
                                                <Link
                                                    className='px-2 md:text-gray-700 hover:text-brand-red font-medium transition duration-200 ease-in-out py-1 rounded'
                                                    activeClassName='bg-brand-red text-white hover:text-white font-bold'
                                                    activeStyle={{color: 'white'}}
                                                    partiallyActive={true}
                                                    to={item.url}>{item.name}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                            </div>
                            <div className='w-130'>
                                <ul className='list-reset flex items-center justify-end'>
                                    <li className='mr-2 inline-flex'>
                                        <a href='https://www.facebook.com/teamisi' target='_blank'
                                           className='bg-brand-grey-lighter text-white flex items-center justify-center h-8 w-8 rounded-full inline-flex transition duration-500 ease-in-out hover:bg-brand-blue'>
                                            <i className="fab fa-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li className='inline-flex'>
                                        <a href='https://www.linkedin.com/company/isi-as'
                                           target='_blank'
                                           className='bg-brand-grey-lighter text-white flex items-center justify-center h-8 w-8 rounded-full inline-flex transition duration-500 ease-in-out hover:bg-brand-blue'>
                                            <i className="fab fa-linkedin-in"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default DesktopHeader;